<template>
  <div class="container">
    <div>
      <img
        style="width: 15px;margin-right: 10px"
        src="../../assets/images/goback.png"
        alt=""
        @click="toBack"
      />
      <span class="headline">产品工序</span>
      <el-button type="primary" class="el-icon-document-add" plain @click="handelAdd">添加工序</el-button>
    </div>
    <el-table
      :data="tableData"
      border
      stripe
      class="table"
      :header-row-style="{color:'#000000'}"
      :height="elementHeight"
    >
      <el-table-column prop="orderNo" label="工序步骤">
        <template slot-scope="scope">工序{{scope.row.orderNo}}</template>
      </el-table-column>
      <el-table-column prop="processNo" label="工序编号"></el-table-column>
      <el-table-column prop="processName" label="工序名称"></el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" plain @click="handleUpdate(scope.row)" icon="el-icon-edit"></el-button>
          <el-button type="text" plain @click="handleDelete(scope.row)" icon="el-icon-delete"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 新增修改产品工序 -->
    <el-dialog :title="title" :visible.sync="dialogVisible" width="478px">
      <el-form ref="form" :model="form" label-width="auto" class="addForm">
        <el-form-item label="工序步骤">
          <el-input
            v-model.number="form.orderNo"
            placeholder="请输入数字"
            oninput="value=value.replace(/[^\d]/g,'')"
            @blur="form.orderNo = Number($event.target.value)"
          />
        </el-form-item>
        <el-form-item label="工序编号">
          <el-input v-model="form.processNo" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="工序名称">
          <el-input v-model="form.processName" placeholder="请输入"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post, put, deleteMethod } from "../../api/http";
export default {
  data() {
    return {
      tableData: [],
      elementHeight: 0,
      id: this.$route.query.id,
      form: {
        productId: this.$route.query.id,
      },
      dialogVisible: false,
      title: "",
      processList: [],
    };
  },
  created() {
    this.getList();
  },
  mounted() {
    let aside = document.querySelector(".aside");
    aside.style.display = "none";
    this.getElementHeight();
  },
  methods: {
    getElementHeight() {
      var clientHeight = window.innerHeight;
      this.$nextTick(() => {
        this.elementHeight = clientHeight - (60 + 30 + 46);
      });
    },
    toBack() {
      this.$router.go(-1);
    },
    // 查询工序列表
    getList() {
      get("/api/VdaProcess/GetByProduct?ProductId=" + this.id).then((res) => {
        this.tableData = res.data;
        this.tableData.sort((a, b) => {
          return a.orderNo > b.orderNo ? 1 : -1;
        });
      });
    },
    // 表单重置
    reset() {
      this.form = {
        orderNo: null,
        processNo: null,
        processName: null,
        productId: this.$route.query.id,
      };
    },
    // 添加工序
    handelAdd() {
      this.reset();
      this.title = "添加工序";
      this.dialogVisible = true;
    },
    // 修改工序
    handleUpdate(row) {
      this.form = row;
      this.title = "修改工序";
      this.dialogVisible = true;
    },
    // 提交按钮
    submitForm() {
      if (this.form.id != null) {
        put("/api/VdaProcess", this.form).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
      } else {
        post("/api/VdaProcess", this.form).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: "新增成功",
            });
            this.dialogVisible = false;
            this.getList();
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 删除按钮
    handleDelete(row) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMethod("/api/VdaProcess?id=" + row.id).then((res) => {
            if (res.code == 200) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getList();
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
  destroyed() {
    let aside = document.querySelector(".aside");
    aside.style.display = "block";
  },
};
</script>

<style lang="less" scoped>
.headline {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin-right: 10px;
}
.table {
  margin-top: 16px;
  ::v-deep .el-table__row td {
    border-bottom: 1px solid #e8e9ebdb !important;
  }
}
.addForm {
  .el-form-item {
    margin-bottom: 10px !important;
    margin-right: 2px;
  }
  ::v-deep .el-input__inner {
    height: 40px !important;
  }
  ::v-deep .el-input {
    width: calc(100% - 22px);
  }
  ::v-deep .el-icon-close {
    margin-left: 8px;
  }
}
</style>